<template>
  <div class="base-nav--item" :class="[`base-nav--${props.variant}`]">
    <div
      v-if="props.variant !== 'mobile'"
      class="item-wrapper"
      v-bind="$attrs"
      @click.stop="
        () => {
          emit('expand-filters');
          isExpanded = !isExpanded;
        }
      "
    >
      <div class="badge-wrapper">
        <span v-if="props.isBadge" class="item-badge" />
        <button
          class="item-link"
          :class="[
            { isActive: props.isBadge },
            { 'font--b1-2': props.isBadge },
            $device.isMobile ? 'font--b1' : 'font--b5-4',
          ]"
        >
          {{ props.text  }}
        </button>
      </div>
      <base-icon
        v-if="props.iconName"
        :name="props.iconName"
        :color="
          props.isBadge ? 'var(--violet-main)' : 'var(--black-monochrome)'
        "
        size="22px"
      />
    </div>
    <div
      v-if="props.isExpandable"
      class="nav--expandable"
      :style="{
        maxHeight: props.isExpandable ? '1600px' : 0,
      }"
    >
      <div
        v-for="(item, index) in useFiltersStore.$state.searchFilters"
        class="item-wrapper"
      >
        <div class="badge-wrapper expanded-wrapper">
          <button
            :class="{
              'font--b1': props.variant === 'mobile',
              'font--b5-4': props.variant === 'decktop',
            }"
            class="item-link"
            style="width: 100%"
            @click="toggleInnerData(index)"
          >
            {{ $t(`search_filters.${item.text}`)  }}
          </button>
          <span
            key="button1"
            style="position: absolute; right: 0"
            @click="toggleInnerData(index)"
          >
            <suspense>
              <base-icon
                name="base/ChevronDown"
                color="var(--black-monochrome)"
                size="18px"
                :rot="innerMap[index] ? '180deg' : '0deg'"
              />
            </suspense>
          </span>
          <span
            v-show="iterationCount == index || innerMap[index]"
            key="button2"
            class="filter__controls"
            style="position: absolute; right: 15px"
            :class="[
              {
                slide_out_right: !innerMap[index] && iterationCount == index,
                slide_in_right: innerMap[index],
              },
            ]"
          >
            <!-- <button
              class="filter__controls-butt font--b6 text-black-monochrome butt-long"
              @click="applyInner(item)"
            >
              Apply
            </button> -->
            <button
              class="filter__controls-butt font--b5-4 text-violet-main"
              @click="clearInnedDebounced(item)"
            >
              {{ $t('search_filters.fr_reset') }}
              <!-- <suspense>
                <base-icon
                  name="base/Close"
                  color="var(--black-monochrome)"
                  size="18px"
                />
              </suspense> -->
            </button>
          </span>
        </div>
        <div
          v-if="item.isExpandable"
          class="inner-expanded"
          :style="{
            maxHeight: innerMap[index] ? 490 + 'px' : 0,
            paddingTop: innerMap[index] ? 12 + 'px' : 0,
          }"
        >
          <div
            v-if="item.type === 'range'"
            ref="expandedInnerRef"
            class="item-wrapper"
          >
            <!-- <price-range-component
              @emit-range="
                (data) => {
                  addPriceRange(data, item);
                }
              "
              :isMobile="props.variant === 'mobile'"
              :price-range="useFiltersStore.$state.priseRange"
              :drop-func="dropPrice"
            /> -->
            <base-price-range-slider
              :drop-func="dropPrice"
              :update-func="updatePrice"
              :style-type="props.variant === 'mobile' ? 'mobile' : 'filters'"
              :min-price="useFiltersStore.$state.priseRange?.priceMin"
              :max-price="useFiltersStore.$state.priseRange?.priceMax"
              :range="useFiltersStore.$state.priseRange?.range"
              @emit-range="
                (data) => {
                  addPriceRange(data, item);
                }
              "
            />
          </div>
          <div
            v-if="item.type === 'irr-range'"
            ref="expandedInnerRef"
            class="item-wrapper"
          >
            <input-range-component
              :name="item.key"
              :placeholder1="item.placeholder1"
              :placeholder2="item.placeholder2"
              :is-slider="true"
              :slider-max="
                Math.trunc(useFiltersStore.$state.currentStats?.irrMax!)
              "
              :slider-min="
                Math.trunc(useFiltersStore.$state.currentStats?.irrMin!)
              "
              :drop-func="dropIrr"
              @change="
                (data) => {
                  addIrrRange(data, item);
                }
              "
            />
          </div>
          <div
            v-if="item.type === 'apr-range'"
            ref="expandedInnerRef"
            class="item-wrapper"
          >
            <input-range-component
              :name="item.key"
              :placeholder1="item.placeholder1"
              :placeholder2="item.placeholder2"
              :is-slider="true"
              :slider-max="
                Math.trunc(useFiltersStore.$state.currentStats?.aprMax!)
              "
              :slider-min="
                Math.trunc(useFiltersStore.$state.currentStats?.aprMin!)
              "
              :drop-func="dropApr"
              @change="
                (data) => {
                  addAprRange(data, item);
                }
              "
            />
          </div>
          <div
            v-if="item.type === 'slider'"
            ref="expandedInnerRef"
            class="item-wrapper"
          >
            <input-range-component
              :name="item.key"
              :placeholder1="item.placeholder1"
              :placeholder2="item.placeholder2"
              :after="item.after"
              :is-slider="true"
              :slider-max="100"
              no-min
              :drop-func="dropMinInvest"
              @change="
                (data) => {
                  addSliderNoMin(data, item);
                }
              "
            />
          </div>
          <!-- <div
            v-if="item.type === 'buttons'"
            ref="expandedInnerRef"
            class="item-wrapper"
            style="gap: 10px"
          >
            <multy-butts-component
              filter-label="Bedrooms"
              @change-butts="addButsFilter"
              :isMobile="props.variant === 'mobile'"
            />
            
            <multy-butts-component
              filter-label="Bathroom "
              @change-butts="addButsFilter"
              :isMobile="props.variant === 'mobile'"
            />
          </div> -->
          <div v-if="item.type === 'checkbox'" class="checkboxes-wrapper">
            <div
              v-for="(inner, InnerIndex) in item.data.filter(
                (q) => q.displayOnFirstLoad
              )"
              ref="expandedInnerRef"
              class="item-wrapper"
              @click="toggleInnerCheckBox(InnerIndex, index, item)"
            >
              <check-box-component
                :checkbox-label="inner.text"
                :label-amount="inner.objAmount"
                :display-on-first-load="inner.displayOnFirstLoad"
                :checkbox-placeholder="inner.checkbox_placeholder"
                :checked="isChecked(index, InnerIndex)"
                :is-mobile="props.variant === 'mobile'"
                :is-zero-amount-displayed="item.key !== 'countryId'"
                :placeholder-checked="
                  typeof inner.noArrow === 'boolean'
                    ? inner.noArrow
                    : item.noArrow
                "
                @change="toggleInnerCheckBox(InnerIndex, index, item)"
              />
            </div>
          </div>
        </div>
        <base-separator
          v-if="index != props.expandedData!.length - 1 && !props.hideSeparator"
          style="margin-top: 14px"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseNavItemProps } from "~/types/base-nav-item";
import checkBoxComponent from "./filters/checkbox.vue";
import { filtersStore } from "~/store/search_filters";
import inputRangeComponent from "./filters/inputRange.vue";
import type { IFiltersItem, IFilters } from "~/store/search_filters";
import { debounce } from "lodash";
import eventBus from "~/utilities/composables/eventBus";

interface Emits {
  (event: "apply-inner", value: IFilters): void;
  (event: "clear-inner", value: IFilters): void;
  (event: "expand-filters"): void;
  (event: "update-price"): void;
}

const useFiltersStore = filtersStore();
const route = useRoute();

const props = withDefaults(defineProps<BaseNavItemProps>(), {
  variant: "decktop",
});
const emit = defineEmits<Emits>();

const isExpanded = ref(true);

const expandedContentRef = ref<HTMLElement | null>(null);
const expandedInnerRef = ref<HTMLElement[] | null>(null);
const expandableRef = ref<HTMLElement | null>(null);
const checkedMap = reactive<{ [key: string]: any }>({
  expandedData: [{ data: [{ isChecked: false }] }],
});
const innerMap = reactive<{ [key: string]: boolean }>({});
const iterationCount = ref<number | null>(null);
const getListHeight = (el: HTMLElement[] | HTMLElement | null) => {
  if (!el || !props.expandedData) {
    return 0;
  }
  let amount = 0;
  if (Array.isArray(el)) {
    el.forEach((item) => {
      amount = item.clientHeight ? item.clientHeight + amount + 24 : 0;
    });
  } else {
    amount = el.clientHeight ? el.clientHeight + amount : 0;
  }

  return amount;
};
const toggleInnerData = (index: number) => {
  iterationCount.value = index;
  if (!innerMap[index]) {
    innerMap[index] = true;
  } else {
    innerMap[index] = false;
  }

  nextTick(() => {
    if (expandableRef.value) {
      expandableRef.value.style.maxHeight =
        getListHeight(expandedInnerRef.value) + "px";
    }
  });
};

const isChecked = (index: number, InnerIndex: number) => {
  return (
    checkedMap.expandedData?.[index]?.data?.[InnerIndex]?.isChecked || false
  );
};

const toggleInnerCheckBox = (
  InnerIndex: number,
  index: number,
  item: IFilters,
  noUpdate?: boolean
) => {
  if (!checkedMap.expandedData) {
    checkedMap.expandedData = [];
  }
  if (!checkedMap.expandedData[index]) {
    checkedMap.expandedData[index] = { data: [] };
  }
  if (!checkedMap.expandedData[index].data[InnerIndex]) {
    checkedMap.expandedData[index].data[InnerIndex] = { isChecked: false };
  }

  checkedMap.expandedData[index].data[InnerIndex].isChecked =
    !checkedMap.expandedData[index].data[InnerIndex].isChecked;

  if (!props.expandedData || !props.expandedData[index]) return;

  if (checkedMap.expandedData[index].data[InnerIndex].isChecked) {
    props.expandedData[index].values?.push(
      props.expandedData[index]!.data[InnerIndex]!.key
    );
    if (!noUpdate) {
      nextTick(() => {
        applyInner(item);
      });
    }
  } else {
    props.expandedData[index].values = props.expandedData[index].values?.filter(
      (item) => item != props.expandedData[index]!.data[InnerIndex]!.key
    );
  }
  if (!noUpdate) {
    nextTick(() => {
      applyInnedDebounced(item);
    });
  }
};

const addPriceRange = (
  value: { price: { gte: number; lte: number } },
  item: IFilters
) => {
  const index = useFiltersStore.searchFilters.findIndex(
    (item) => item.key === "tokenPrice"
  );
  if (index !== -1) {
    useFiltersStore.$state.searchFilters[index].gte = value.price.gte;
    useFiltersStore.$state.searchFilters[index].lte = value.price.lte;
    item.gte = value.price.gte;
    item.lte = value.price.lte;
  }
  nextTick(() => {
    applyInnedDebounced(item, true);
  });
};

const addIrrRange = (
  value: { irr: { gte: number; lte: number } },
  item: IFilters
) => {
  const index = useFiltersStore.searchFilters.findIndex(
    (item) => item.key === "irr"
  );

  if (index !== -1) {
    item.gte = value.irr.gte;
    item.lte = value.irr.lte;
    useFiltersStore.$state.searchFilters[index].gte = item.gte;
    useFiltersStore.$state.searchFilters[index].lte = item.lte;
    nextTick(() => {
      applyInnedDebounced(item);
    });
  }
};

const addAprRange = (
  value: { apr: { gte: number; lte: number } },
  item: IFilters
) => {
  const index = useFiltersStore.searchFilters.findIndex(
    (item) => item.key === "apr"
  );
  if (index !== -1) {
    item.gte = value.apr.gte;
    item.lte = value.apr.lte;
    useFiltersStore.$state.searchFilters[index].gte = item.gte;
    useFiltersStore.$state.searchFilters[index].lte = item.lte;
    nextTick(() => {
      applyInnedDebounced(item);
    });
  }
};
const addSliderNoMin = (
  value: { minimumInvestment: { lte: number } },
  item: IFilters
) => {
  const index = useFiltersStore.searchFilters.findIndex(
    (item) => item.key === item.key
  );
  if (index !== -1) {
    item.lte = value.minimumInvestment.lte;
    useFiltersStore.$state.searchFilters[index].lte = item.lte;
    nextTick(() => {
      applyInnedDebounced(item);
    });
  }
};

const applyInner = (item: IFilters, isPrice?: boolean) => {
  emit("apply-inner", item);
  if (!isPrice) {
    emit("update-price");
  }
};

const applyInnedDebounced = debounce((item: IFilters, isPrice?: boolean) => {
  applyInner(item, isPrice);
}, 300);

const clearInnedDebounced = debounce((item: IFilters) => {
  clearInner(item);
}, 300);

const clearInner = (item: IFilters) => {
  const index = useFiltersStore.searchFilters.findIndex(
    (q) => q.key === item.key
  );
  if (index !== -1) {
    if (item.values) {
      item.values.forEach((r) => {
        const innerIndex = useFiltersStore.searchFilters[index].data.findIndex(
          (q) => q.key === r
        );
        if (innerIndex !== -1) {
          checkedMap.expandedData[index].data[innerIndex].isChecked = false;
        }
      });
    } else if (
      Object.hasOwn(item || {}, "gte") &&
      Object.hasOwn(item || {}, "lte")
    ) {
      item.gte = useFiltersStore.$state.savedStats[`${item.key}Min`] ?? 0;
      item.lte = useFiltersStore.$state.savedStats[`${item.key}Max`] ?? 100;
      setTimeout(() => {
        switchDrop(item);
      }, 1000);
    }
  }
  emit("clear-inner", item);
};

const updateExpandanleHeight = (isExpanded: boolean) => {
  setTimeout(() => {
    const h = isExpanded ? 1600 : 0;
    expandedContentRef.value?.setAttribute("style", `max-height:${h + "px"}`);
  }, 200);
  return 1600;
};

const switchDrop = (item: IFilters) => {
  switch (item.key) {
    case "irr":
      return eventBus.emit("drop-irr-range", [item.gte, item.lte]);
    case "apr":
      return eventBus.emit("drop-apr-range", [item.gte, item.lte]);
    case "tokenPrice":
      return eventBus.emit("drop-price-range", [item.gte, item.lte]);
    case "minimumInvestment":
      return eventBus.emit("drop-min-invest-range", [0, 100]);
  }
};

const dropIrr = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-irr-range", ctx);
  };
};
const dropApr = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-apr-range", ctx);
  };
};
const dropMinInvest = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-min-invest-range", ctx);
  };
};
const dropPrice = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-price-range", ctx);
  };
};
const updatePrice = () => {
  return function (ctx: () => void) {
    eventBus.on("update-price-range", ctx);
  };
};
const dropCheckBoxes = () => {
  checkedMap.expandedData.forEach((item) => {
    item.data.forEach((q) => {
      q.isChecked = false;
    });
  });
};

const checkQueryCheckBoxes = () => {
  const updateableKeys = new Set(["bedroom", "bathroom", "countryId", "stage"]);
  const queryKeys = Object.entries(route.query).map(([key, value]) => ({
    cleanedKey: key.replace(/\[.*?\]/g, ""),
    value,
  }));

  useFiltersStore.searchFilters.forEach((filter, index) => {
    if (!updateableKeys.has(filter.key)) return;

    filter.data.forEach((item, innerIndex) => {
      const matchedQuery = queryKeys.find(
        (q) => q.cleanedKey === filter.key && q.value == item.key
      );
      if (matchedQuery) toggleInnerCheckBox(innerIndex, index, filter, true);
    });
  });
};

const expandAll = () => {
  useFiltersStore.$state.searchFilters.forEach((q, index) => {
    innerMap[index] = true;
  });
};

onMounted(() => {
  eventBus.on("drop-checkbox-filters", dropCheckBoxes);
  eventBus.on("update-checkbox-filters", checkQueryCheckBoxes);
  expandAll();
});

</script>

<style scoped lang="scss">
.base-nav {
  &--item {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: flex-start;
    width: 100%;

    .checkboxes-wrapper {
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: var(--white-monochrome);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray-40);

        border-radius: 6px;
        border: 2px solid transparent;
      }

      @supports not selector(::-webkit-scrollbar) {
        * {
          scrollbar-color: var(---sky-200) var(--sky-100);
        }
      }
    }
    .item-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      min-width: 147px;
      cursor: pointer;
      .inner-expanded {
        overflow: hidden;
        transition: max-height 0.4s ease,padding-top 0.4s ease;
        display: grid;
        align-items: center;
        gap: 14px;
      }
      .badge-wrapper {
        display: flex;
        align-items: center;
        justify-items: flex-start;
        width: 100%;
        gap: 8px;
        .item-badge {
          display: block;
          width: 4px;
          height: 16px;
          border-radius: 0px 10px 10px 0px;
          background: linear-gradient(180deg, #6742d0 0%, #1f0764 100%);
        }
        .item-link {
          text-align: left;
          color: var(--black-monochrome);
          background: transparent;
          border: none;
          outline: none;
          margin: 0;
          padding: 0;
          padding-left: 14px;
          cursor: pointer;
        }
        .isActive {
          color: var(--violet-main);
          padding-left: 0;
        }
      }
    }
    .nav--expandable {
      max-height: 0;
      height: fit-content;
      overflow: hidden;
      transition: max-height 1s ease;
      display: grid;
      grid-template-columns: 1fr;
      justify-self: center;
      gap: 12px;
      padding-top: 18px;
      padding-left: 8px;
      width: 100%;
      .item-wrapper {
        display: grid;
        height: fit-content;
        gap: 0;
      }
      .expanded-wrapper {
        gap: 0;
        justify-content: space-between;
        position: relative;
        .item-link {
          padding-left: 0;
        }
        .filter__controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;

          &-butt {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            width: 100%;
            transition: scale ease 0.2s;
            cursor: pointer;
            &:active {
              scale: 0.9;
            }
          }
          .butt-round {
            border-radius: 50%;
            background: var(--gray-40);
            border: none;
            width: 25px;
          }
          .butt-long {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }

  &--mobile {
    width: 100%;
    .nav--expandable {
      padding: 0;
      .item-wrapper {
        .inner-expanded {
          .item-wrapper {
            padding-left: 25px;
            :deep(.base-price-range) {
              .base-separator {
                background: var(--black-monochrome-60);
                height: 2px;
              }
              .chart-wrapper {
                canvas {
                  max-height: 50px !important;
                  height: 50px !important;
                }
              }
              .price-input {
                background: var(--white-monochrome);
              }
            }
          }
        }
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide_in_right {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide_out_right {
  animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>
